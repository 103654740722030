import { UserManager, Log, User } from 'oidc-client';
import _ from 'lodash';
import { BroadcastChannel } from 'broadcast-channel';

import { settings } from '../config/settings';
import { getUserToken } from 'utils/userToken';

const logoutChannel = new BroadcastChannel('logout');

const parseJWT = (token: string) => {
  try {
    const b64payload = token.split('.')[1];
    // Ref: https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
    const payload = decodeURIComponent(escape(window.atob(b64payload)));
    return JSON.parse(payload);
  } catch (err) {
    return null;
  }
};

class CustomUserManager extends UserManager {
  async storeUser(user: User) {
    const basicUser = user;

    if (basicUser && basicUser?.profile) {
      // Removing sensitive information from the profile so oidc can store only the needed tokens in the session storage
      const {
        name,
        given_name,
        family_name,
        preferred_username,
        gender,
        birthdate,
        birthDate,
        ci,
        faceProfile,
        picture,
        email,
        email_verified,
        groups,
        ...rest
      } = basicUser.profile;
      basicUser.profile = rest;
    }

    return super.storeUser(basicUser);
  }
}

export default class AuthService {
  userManager: CustomUserManager;

  constructor() {
    this.userManager = new CustomUserManager(settings());
    // Logger
    Log.logger = console;
    Log.level = Log.WARN;
    this.userManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
    });

    this.userManager.events.addAccessTokenExpired(async () => {
      console.log('token expired');
      await this.signinSilent();
    });
  }

  signinRedirectCallback = async () => {
    if (!window.location.search.includes("state=")) {
      console.warn("No state parameter found in URL, skipping callback.");
      return;
    }

    try {
      await this.userManager.signinRedirectCallback();
    } catch (err) {
      console.log("OIDC state in sessionStorage:", sessionStorage.getItem("oidc.state"));
      console.log("OIDC callback error:", err);
    }
  };

  getUser = async () => {
    const user = await this.userManager.getUser();
    if (!user) {
      return this.userManager.signinRedirectCallback();
    }
    return user;
  };

  signinRedirect = async () => {
    try {
      await this.userManager.signinRedirect({});
    } catch (err) {
      console.log(err);
    }
  };

  isAuthenticated = (): boolean => {
    const oidcStorage = getUserToken();
    return !!oidcStorage && !!oidcStorage.access_token;
  };

  signinSilent = async () => {
    try {
      await this.userManager.signinSilent();
    } catch (err) {
      console.log(err);
    }
  };

  logout = async () => {
    try {
      await this.userManager.signoutRedirect();
      logoutChannel.postMessage('Logout');
    } catch (err) {
      console.log(err);
    }
  };

  logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
      setTimeout(async () => {
        await this.userManager.signoutRedirect();
      }, 5000);
      logoutChannel.close();
    };
  };

  isAdmin = async () => {
    // TODO: Fix the issue ralted to this.userManager.getUser()
    // does not return resource_access property but exists in the token
    const oidcStorage = getUserToken();
    const profile = parseJWT(oidcStorage.access_token);
    // eslint-disable-next-line camelcase
    let roles = profile?.resource_access?.ponctuel?.roles;
    roles = (roles && _.isArray(roles)) ? roles : [];
    return _.includes(roles, 'administrator');
  };
}
